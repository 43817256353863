import React from "react"

import SEO from "@components/seo"
import * as data from "@data/pledge"
import Layout from "@components/layout"
import HeaderAndText from "@components/header_with_text"
import ProgressBar from "@components/_atoms/progress_bar_reg"
import ButtonParentRegistrationShare from "@components/_molecules/button_parent_registration_share"

const ParentRegistrationInvite = () => {
  const { invite } = data
  const handleClick = function() {}
  return (
    <Layout pageClass="pledge" type="gray">
      <SEO title={invite.title} />
      <ProgressBar />
      <div style={{ height: `20px`, display: `block` }}></div>
      <HeaderAndText data={invite.heading} />
      <div style={{ height: `11rem`, width: `100%` }} />
      <ButtonParentRegistrationShare />
    </Layout>
  )
}

export default ParentRegistrationInvite
