import React from "react"
import "./style.scss"

interface IButtonParameters {
  text: string
  onClick(): void
}

const ButtonOnClick = (params: IButtonParameters) => {
  return (
    <button className="button-on-click" onClick={params.onClick}>
      {params.text}
    </button>
  )
}

export default ButtonOnClick
