import React from "react"
import ButtonOnClick from "@components/_atoms/button_on_click"
import LAEJS from "@services/LAEJS"
import * as progress from "@services/progress"
const debug = require("debug")("lae:" + __filename)

const ButtonParentRegistrationShare = () => {
  const onShareComplete = () => {
    progress.regComplete()
  }
  const handlerOnClick = () => {
    LAEJS.jsOnShare({
      json: {
        title:
          "Learn & Earn is the best way to earn real money and become an investor.",
        text:
          "I just created a Learn & earn account for you. Download the app to unlock the ca$h I donated.",
        url: process.env.INVITE_URL
      }
    })
    LAEJS.jsOnShareComplete({ done: onShareComplete })
  }

  return <ButtonOnClick text="Send Invite" onClick={handlerOnClick} />
}

export default ButtonParentRegistrationShare
